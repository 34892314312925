require('../scss/app.scss');
require('./svg/svg');

window.noZensmooth = true;

require('./common/true-vh');
require('./common/goal');
require('./common/goals2');
require('./common/js-validation');
require('./common/links');
require('./common/main');
require('./common/tour-modal');
require('./common/pagination');
require('./common/auto-pagination');
require('./common/respond');
require('./common/map');
require('./common/need-animation');
require('./common/webp-polyfill');
require('./common/hover-listener');
require('./common/scroll-up');
require('./common/axios');
require('./common/jivosite-killer');
// require('./common/ajax-redirect');
require('./common/tippy'); // Тултипы при наведении
require('fslightbox');

require('./field/phone');
require('./field/range'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');
require('../fonts/Hoves/stylesheet.css');
require('./accordion/initializator');
require('./accordion/switchable-accordion');
require('./field/custom-select');
require('./filter/filter');
require('./filter/sellerFloorFilter');
require('./filter/articleFilter');
require('./filter/awardsFilter');
require('./filter/about-building-filter');

require('./jivo/initializator');

require('./sliders/sliders');
require('./advantage/add-class');

require('./customer/favorites');
require('./customer/favorites-list');
require('./customer/compare');
require('./customer/compare-list');

require('./seller/menu');
require('./seller/customer-search');
require('./seller/responsive-lists');
require('./seller/compilation');
require('./seller/compilation-list');
require('./seller/deal');
require('./seller/sliders');

require('./agency/set-admin');

require('./header/header');
require('./header/menu-items-animation');
require('./header/drop-menu');
require('./header/menu-items-animation');
require('./genplan/index');
require('./genplan/infra-off');
require('./credit/index');
require('./project-map/index');
require('./project-office/index');
require('./project-map/project-map-filter');
require('./offices/index');
require('./project/nav-menu');
require('./share/share-opener');

require('./catalog/windowSizeChecker');
require('./catalog/sticky-mob-filter');
require('./banks/modal');
require('./common/print');
require('./pop-up/popup');

require('./bitrix/embed-apartment-deal-action');
require('./seller/manage/manageRequest');

require('./table/switcher');

require('./cookie-confirm/confirm');