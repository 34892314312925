// Ждем пока завершится загрузка страницы
document.addEventListener('DOMContentLoaded', () => {
  // Проверяем, есть ли в localStorage запись о том, что пользователь уже согласился на куки
  if (localStorage.getItem('cookie-confirm') === 'true') return;

  // Если записи нет, то показываем окно согласия
  const popup = document.querySelector('#cookie-confirm-popup');
  if (popup) popup.style.display = 'flex';

  // При клике на кнопку "Согласен" записываем в localStorage, что пользователь согласился
  const button = popup.querySelector('#cookie-confirm-button');
  if (button) {
    button.addEventListener('click', () => {
      localStorage.setItem('cookie-confirm', 'true');
      popup.style.display = 'none';
    });
  }
});